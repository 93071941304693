import { useStaticQuery, graphql } from "gatsby"

const useMediumData = () => {
  const posts = useStaticQuery(graphql`
    query {
      allMediumPost(limit: 10, sort: {fields: date, order: DESC}) {
        edges {
          node {
            thumbnail
            link
            title
            content
          }
        }
      }
    }
  `)

  return posts.allMediumPost.edges
}

export default useMediumData