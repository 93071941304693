import React, { useRef, useState } from "react"
import cx from "classnames"
import Img from "gatsby-image"
import { FaHeart, FaComment, FaPlay, FaImages } from "react-icons/fa"
import useInstagramData from "../../hooks/use-instagram-data"
import styles from "./instagram.module.scss"
import useWindowResize from "../../hooks/use-window-resize"

/**
 * Truncate `str` at `len` with ending `end`.
 * @param {string} str String to truncate
 * @param {number} len Optimal length of string
 * @param {string} end Tag onto end of string
 */
const ellipsis = (str, len = 140, end = "...") => {
  if (str.length <= len) return str
  let lastWhitespace = -1
  for (let i = len; i >= 0; i -= 1) {
    if (" \n\t".includes(str[i])) {
      lastWhitespace = i
      if (";.,".includes(str[i - 1])) lastWhitespace = i - 1
      break
    }
  }
  if (lastWhitespace === -1) return str.substring(0, len) + end
  return str.substring(0, lastWhitespace) + end
}

/**
 * A custom Instagram feed that pulls from static data.
 * Preferred over the Juicer feed for performance and accessibility.
 */
const InstagramFeed = ({ primary }) => {
  const { heading, view_all_text, view_all_link } = primary
  const data = useInstagramData()
  const ref = useRef([])
  const [postWidth, setPostWidth] = useState([])

  // flexbox polyfill
  // adjust columns to be equal height using child padding
  useWindowResize(() => {
    if (ref.current?.children?.length > 0) {
      const children = Array.from(ref.current.children).map(column =>
        Array.from(column.children)
      )
      const heights = children.map(column =>
        // sum of post heights (+16px default margin +2 default border)
        column.reduce((acc, post) => acc + post.clientHeight + 22, 0)
      )
      const maxHeight = Math.max(...heights)
      const adjusts = children.map((column, i) => {
        const offset = maxHeight - heights[i]
        const adjust = offset / (column.length - 1)
        // 16px default margin
        return Math.ceil(adjust)
      })
      adjusts.forEach((adjust, i) => {
        const posts = children[i]
        posts.forEach((post, j) => {
          // eslint-disable-next-line no-param-reassign
          post.style.marginBottom =
            j === posts.length - 1 ? "20px" : `${adjust + 20}px`
        })
      })

      setPostWidth(ref.current.getElementsByTagName("img")[0].clientWidth)
    }
  })

  // distribute into posts into [1/3] columns based on screen size
  const columns = data.reduce(
    (cols, post, i) => {
      const img = post.localFile.childImageSharp.fluid
      const colNum = i % cols.length
      cols[colNum].push(
        <article className={styles.post} key={post.id}>
          <a
            className={styles.wrapper}
            href={`https://instagram.com/p/${post.id}`}
            taborder={cols[colNum].length}
            target="_blank" rel="noopener noreferrer"
          >
            <div className={styles.media}>
              {post.mediaType === "VIDEO" && (
                <div
                  className={styles.icon}
                  style={{
                    fontSize: "40px",
                    top: `${(postWidth / img.aspectRatio - 40) / 2}px`,
                    margin: "auto",
                  }}
                  aria-label="Video"
                >
                  <span
                    aria-hidden
                    style={{
                      top: "-1px",
                      color: "black",
                      opacity: 0.75,
                    }}
                  >
                    <FaPlay style={{ transform: "translateX(-50%)" }} />
                  </span>
                  <span
                    aria-hidden
                    style={{
                      color: "white",
                      opacity: 0.5,
                    }}
                  >
                    <FaPlay style={{ transform: "translateX(-50%)" }} />
                  </span>
                </div>
              )}
              {post.mediaType === "CAROUSEL_ALBUM" && (
                <div
                  className={styles.icon}
                  style={{
                    fontSize: "20px",
                    top: "5px",
                  }}
                  aria-label="Photo gallery"
                >
                  <span
                    aria-hidden
                    style={{
                      top: "-1px",
                      left: `${postWidth - 5}px`,
                      color: "black",
                      opacity: 0.75,
                    }}
                  >
                    <FaImages />
                  </span>
                  <span
                    aria-hidden
                    style={{
                      left: `${postWidth - 6}px`,
                      color: "white",
                      opacity: 0.5,
                    }}
                  >
                    <FaImages />
                  </span>
                </div>
              )}
              <Img
                fluid={img}
                className={cx(styles.image, "no-a11y-transform")}
                role="img"
                aria-labelledby={`caption-${post.id}`}
                style={{ height: `${postWidth / img.aspectRatio}px` }}
              />
            </div>
            <p id={`caption-${post.id}`} className={styles.caption}>
              <strong>{post.username}</strong>
              <br />
              {ellipsis(post.caption)}
            </p>
            <p className={styles.metrics}>
              {post.likes > 0 && (
                <span className={styles.metric}>
                  {post.likes} <FaHeart aria-label="likes" />
                </span>
              )}
              {post.comments > 0 && (
                <span className={styles.metric}>
                  {post.comments} <FaComment aria-label="comments" />
                </span>
              )}
            </p>
          </a>
        </article>
      )
      return cols
    },
    typeof window === "object" && window.innerWidth < 640
      ? [[]] // small-12
      : [[], [], []] // medium-4
  )

  return (
    <div className={cx("row", styles.feed)} ref={ref} id="instagram-feed">
      <div className="columns small-12">
        <h3 className={styles.storiesHeader}>{heading.text}</h3>
      </div>
      {columns.map((nodes, i) => {
        return (
          <div
            className="columns small-12 medium-4"
            // eslint-disable-next-line react/no-array-index-key
            key={i}
          >
            {nodes}
          </div>
        )
      })}
      <div className={styles.btnBox}>
        <a href={view_all_link?.url} className={styles.viewAllBtn} target="_blank" rel="noopener noreferrer">{view_all_text}</a> 
      </div>
    </div>
  )
}

export default InstagramFeed
