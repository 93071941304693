import React from "react"
import styles from "./podcaststories.module.scss"
import cx from "classnames"
import useVimeoData from "../../hooks/use-vimeo-data"
/**
 * Renders title text and description.
 */
const PodcastStories = ({ primary, items }) => {
  const {section_header, embed_link} = primary
  return (
    <section className={styles.videoStories} id="podcasts">
      <div className={cx("grid-container full",styles.container)} style={{ maxWidth: "1280px"}}>
        <div className="grid-x" style={{alignItems:"center", justifyContent:"center"}}>
          <div className="cell large-10">
            <h3>{section_header}</h3>
          </div>
        </div>
        <div className="grid-x" style={{alignItems:"center", justifyContent:"center"}}>
          <div className="cell large-10" style={{border:"2px solid #ffffff"}}>
            <iframe
              allow="autoplay *; encrypted-media *; fullscreen *"
              frameBorder="0"
              height="450"
              style={{
                width: "100%",
                overflow: "hidden",
                background: "transparent",
              }}
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
              src={embed_link.url}
            />
          </div>
        </div>
      </div>

    </section>
  )
}

export default PodcastStories
