import React from "react"
import {
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaFacebookSquare,
  FaYoutube,
} from "react-icons/fa"
import cx from "classnames"
import styles from "./social.module.scss"

const fa = {
  FaLinkedin,
  FaTwitter,
  FaInstagram,
  FaFacebookSquare,
  FaYoutube,
}
const SocialButtons = ({ primary, items }) => {
  const { above, below } = primary

  return (
    <div className={cx("row", styles.social)}>
      <div
        className="small-12 columns"
        dangerouslySetInnerHTML={{ __html: above.html }}
      />
      <ul>
        {items.map(item => {
          if (!(item.logo in fa)) {
            return <>Icon {item.logo} must be added to codebase</>
          }
          return (
            <li
              key={item.link.url}
              className={cx("columns small-4", styles.logo)}
            >
              <a href={item.link.url} style={{ color: item.color }}>
                {React.createElement(fa[item.logo], {
                  "aria-label": item.logo_label,
                })}
              </a>
            </li>
          )
        })}
      </ul>
      <div
        className="small-12 columns"
        dangerouslySetInnerHTML={{ __html: below.html }}
      />
    </div>
  )
}

export default SocialButtons
