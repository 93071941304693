import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

const useVimeoData = () => {
  const data = useStaticQuery(graphql`
    query {
	    allVimeo(limit: 6) {
		    edges {
		      node {
		        link
		        pictures {
		          base_link
		        }
		        name
		        description
		        id
		        duration
		      }
		    }
		  }
	  }
	`)  
	return data.allVimeo.edges
}

export default useVimeoData