import { useStaticQuery, graphql } from "gatsby"

const useInstagramData = () => {
  const data = useStaticQuery(graphql`
    query {
      allInstaNode(sort: { fields: timestamp, order: DESC }, limit: 9) {
        nodes {
          id
          caption
          likes
          comments
          mediaType
          username
          localFile {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return data.allInstaNode.nodes
}

export default useInstagramData