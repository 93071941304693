import React from "react"
import styles from "./videostories.module.scss"
import cx from "classnames"
import useVimeoData from "../../hooks/use-vimeo-data"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './tabStyle.css'
import ReactPlayer from 'react-player'

/**
 * Renders title text and description.
 */
const VideoStories = ({ primary, items }) => {
  const {
    section_header, 
    view_all_text, 
    view_all_link, 
    project_shorts_text, 
    project_shorts_link, 
    project_docs_text, 
    project_docs_link
  } = primary
  const video_data = useVimeoData()
  const ellipsis = (str, len = 70, end = "...") => {
    if (str.length <= len) return str
    let lastWhitespace = -1
    for (let i = len; i >= 0; i -= 1) {
      if (" \n\t".includes(str[i])) {
        lastWhitespace = i
        if (";.,".includes(str[i - 1])) lastWhitespace = i - 1
        break
      }
    }
    if (lastWhitespace === -1) return str.substring(0, len) + end
    return str.substring(0, lastWhitespace) + end
  }
  return (
    <section className={styles.videoStories} id="story-videos">
      <div className={cx("grid-container full",styles.container)} style={{ maxWidth: "1280px"}}>
        <div className="grid-x" style={{alignItems:"center", paddingBottom:"58px"}}>
          <div className="cell large-12">
            <h3>{section_header}</h3>
          </div>
        </div>
        <Tabs>
          <div className="grid-x grid-padding-x" style={{flexDirection:"row-reverse", flexWrap:"wrap-reverse"}}>
            <div className="cell large-5">
              <TabList className={styles.playlistBar}>
                {video_data.map(post => (
                  <Tab className={styles.playlistItem}>
                    <img src={post.node.pictures.base_link}/>
                    <div>
                      <p>{ellipsis(post.node.name)}</p>
                      <h5>&#9654; Play Now</h5>
                    </div>
                  </Tab>
                ))}
              </TabList>
            </div>
            <div className="cell large-7">
              <div>
                {video_data.map(post => (
                  <TabPanel className={styles.tabPanel}>
                    <ReactPlayer url={post.node.link} controls={true} className={styles.iframeWrap}/>
                    <h4>{post.node.title}</h4>
                    <p className={styles.time}><b>Length:</b> {Math.round(post.node.duration/60)} min</p>
                    <p>{post.node.description}</p>

                  </TabPanel>
                ))}
              </div>

            </div>
          </div>
          <div className={styles.btnBox}>
            <a href={view_all_link?.url} className={styles.viewAllBtn} target="_blank" rel="noopener noreferrer">{view_all_text}</a>
            <a href={project_shorts_link?.url} className={styles.otherBtn} target="_blank" rel="noopener noreferrer">{project_shorts_text}</a>
            <a href={project_docs_link?.url} className={styles.otherBtn} target="_blank" rel="noopener noreferrer">{project_docs_text}</a>
          </div>
        </Tabs>
      </div>

    </section>
  )
}

export default VideoStories
