import React from "react"
import styles from "./storytabs.module.scss"
import cx from "classnames"
/**
 * Renders title text and description.
 */
const StoryTabs = ({ primary }) => {
  const {
    section_content,
    videos_tab_header,
    podcasts_tab_header,
    articles_tab_header,
    instagram_tab_header
  } = primary
  return (

    <section className={cx(styles.storyTabs,section_content? styles.top : styles.noTop)}>
      <div className="grid-container">
        <div className="grid-x" style={{justifyContent:"space-evenly",textAlign:"center", maxWidth:"100%"}}>
          <div className="cell medium-10">
            <div dangerouslySetInnerHTML={{ __html: primary.section_content?.html }} className={section_content ? styles.hasContent : styles.noContent}/>
          </div>
        </div>
        <div className={styles.icons}>
          <div className="grid-x" style={{justifyContent:"space-evenly",textAlign:"center", maxWidth:"100%"}}>
            <div className="cell medium-3">
              <a href="#story-videos"><span className={styles.videos}/>{videos_tab_header}</a>
            </div>
            <div className="cell medium-3">
              <a href="#podcasts"><span className={styles.podcasts}/>{podcasts_tab_header}</a>
            </div>
            <div className="cell medium-3">
              <a href="#story-articles" ><span className={styles.article}/> {articles_tab_header}</a>
            </div>
            <div className="cell medium-3">
              <a href="#instagram-feed"><span className={styles.instagram}/>{instagram_tab_header}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default StoryTabs
