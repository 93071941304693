import React, { Component } from "react"
import styles from "./articlestories.module.scss"
import cx from "classnames"
import useMediumData from "../../hooks/use-medium-data"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/**
 * Renders title text and description.
 */
const ArticleStories = ({ primary }) => {
  const { section_header, button_text } = primary
 
  function strip(str) {
    return str.replace(/<[^>]+>/g, '');
  } 
  const ellipsis = (str, len = 140, end = "...") => {
    if (str.length <= len) return str
    let lastWhitespace = -1
    for (let i = len; i >= 0; i -= 1) {
      if (" \n\t".includes(str[i])) {
        lastWhitespace = i
        if (";.,".includes(str[i - 1])) lastWhitespace = i - 1
        break
      }
    }
    if (lastWhitespace === -1) return str.substring(0, len) + end
    return str.substring(0, lastWhitespace) + end
  }
  function NextArrow(props) {
    const { className, onClick } = props;
    return (
      <div
        className={styles.nextArrow}
        onClick={onClick}
      />
    );
  }
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <NextArrow />,

    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  const isEn = process.env.GATSBY_BUILD_LANG !== "fr"
  const host = isEn ? `https://risingyouth.ca` : `https://jeunesenaction.com`
  const posts = useMediumData()
  return (
    <section className={styles.articleStories} id="story-articles">
      <div className={cx("grid-container full",styles.container)} style={{ maxWidth: "1280px"}}>
        <div className="grid-x" style={{}}>
          <div className="cell large-12">
            <h3>{ section_header }</h3>
          </div>
          <Slider {...settings} className={styles.storyCarousel}>
            {posts.map(post => (
              <div className={styles.carouselItem}>
                <a className={styles.articleImg} href={post.node.link} target="_blank" rel="noopener noreferrer">
                  <img src={post.node.thumbnail} />
                </a>
                <a className={styles.articleTitle} href={post.node.link} target="_blank" rel="noopener noreferrer"><h4>{post.node.title}</h4></a>
                <p>{ellipsis(strip(post.node.content))}</p>
                <a className={styles.readMore} href={post.node.link} target="_blank" rel="noopener noreferrer">Read More &rarr;</a>
              </div>
            ))}
          </Slider>
          <a href={isEn ? ` https://medium.com/risingyouth ` : ` https://medium.com/jeunesenaction`} className={styles.viewAllBtn} target="_blank" rel="noopener noreferrer">{button_text}</a>          
        </div>
      </div>
    </section>
  )
}

export default ArticleStories
