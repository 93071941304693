import React from "react"
import styles from "./featuredstory.module.scss"
import cx from "classnames"
/**
 * Renders title text and description.
 */
const FeaturedStory = ({ primary}) => {
  const {section_header, story_content, embd_media} = primary
  return (
    <section className={styles.featuredStory}>
      <div className={cx("grid-container full",styles.container)} style={{ maxWidth: "1280px"}}>
        <div className="grid-x " style={{ alignItems:"center",justifyContent:"space-between"}}>
          <div className="cell large-5">
            <h3 className={styles.header}>{section_header}</h3>
            <div
              className={styles.copy}
              dangerouslySetInnerHTML={{ __html: story_content.html }}
            />
          </div>
          <div className="cell large-6">
            <div className={styles.iframeWrap}style={{}}>
              <iframe src={embd_media.embed_url} 
              frameborder="0" 
              allow="autoplay; fullscreen; picture-in-picture" 
              allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FeaturedStory
